<template>
  <div class="options" :class="{ active: active }">
    <div class="video-progress">
      <span class="progress" :style="'width: ' + progress * 10 + '%;'"></span>
    </div>
    <v-responsive :aspect-ratio="5 / 1">
      <v-container fill-height>
        <v-row justify="center" class="fill-height">
          <v-col cols="6">
            <div
              class="pa-2 option experience-option"
              :class="{ selected: selected.a, anyselected: selected.any }"
              @click="selectOption(meta.OpAId, 'a')"
              :data-option="meta.OpAId"
            >
              <span>{{ meta.OpAText }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <div
              class="pa-2 option right experience-option"
              :class="{ selected: selected.b, anyselected: selected.any }"
              @click="selectOption(meta.OpBId, 'b')"
              :data-option="meta.OpBId"
            >
              <span>{{ meta.OpBText }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      selected: {
        any: false,
        a: false,
        b: false
      }
    };
  },
  props: ["meta", "active", "time"],
  methods: {
    selectOption(val, option) {
      this.selected.any = true;
      if (option == "a") {
        this.selected.a = true;
        this.selected.b = false;
      }
      if (option == "b") {
        this.selected.a = false;
        this.selected.b = true;
      }
      this.$emit("selectOption", val);
      this.$gtag.event("option_selected", { choice: val });
    }
  },
  computed: {
    progress() {
      if (this.time < 20) {
        return this.time;
      } else {
        return 0;
      }
    }
  },
  watch: {
    meta() {
      this.selected.a = false;
      this.selected.b = false;
      this.selected.any = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.options {
  position: relative;
  opacity: 0;
  transition: 1s ease-out;
  top: 4%;
  padding-top: 0.6vh;
  text-align: center;

  .video-progress {
    width: calc(100% - 21px);
    height: 10px;
    background-color: #000;
    position: relative;
    top: 0;
    display: inline-block;
    margin: 0 7px 0 14px;
    padding: 0;
    overflow: hidden;

    span {
      display: inline-block;
      height: 200%;
      width: 100%;
      background-color: #d62a29;
      line-height: 10px;
      position: relative;
      transition: width 0.2s;
    }
  }

  .option {
    overflow: hidden;
    font-family: "Franchise";
    background: url(../assets/btn-left-black.png) no-repeat;
    background-size: 100% 100%;
    width: calc(100% - 30px);
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    transition: 0.2s ease-out;
    opacity: 0.8;
    position: relative;
    margin: 0 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.4vw;

    span {
      opacity: 0;
    }

    &.right {
      background-image: url(../assets/btn-right-black.png);
    }

    &.selected {
      opacity: 1;
      span {
        opacity: 1;
      }
    }
    &.anyselected:not(.selected) {
      filter: brightness(0.3) sepia(0.6);
      -webkit-filter: brightness(0.3) sepia(0.6);
    }
  }

  &.active {
    opacity: 1;
    .option {
      cursor: pointer;
      span {
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
